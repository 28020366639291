body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.homePageSlider1 img {
  max-width: 100%;
}
.aboutPage img {
  max-width: 100%;
}
.banner-img img {
  max-width: 100%;
}

.img100 img {
  max-width: 100%;
}

.logo img {
  width: 100px;
}

.zindexne {
  z-index: -1;
}

.invoceCantiner {
  margin-bottom: 150px;
}
.invoceCantiner1 {
  margin-top: 150px;
}

.proRight {
  position: relative;
  z-index: 1;
  min-height: 300px;
}

.mince {
  position: relative;
  z-index: -1;
}

.ProBtn {
  position: absolute;
  bottom: 33px;
}

.thememan{
  background: #000000;
}
.thememan .main-wrap {
  margin-left: 300px;
  background-color: #000000 !important;
  position: relative;
}
.thememan .navbar-aside {
  /* max-width: 300px; */
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 999999;
  border-right: 1px solid #eee;
}
.btn {
  height: 36px;
}
.dtpick input.form-control.react-datepicker-ignore-onclickoutside {
  background: #fbfbfb;
}

.custom-radio input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 14px;
  -webkit-box-shadow: none;
  /* box-shadow: none; */
  padding-left: 20px;
  font-size: 16px;
  width: 10%;
}

.brand-wrap h6{
font-size: 20px;
color: #000000;
}
.thememan .brand-wrap h6{
font-size: 20px;
color: #ffffff;
}
.thememan .menu-aside .menu-link {
  padding: 10px;
  font-weight: 800;
  font-size: 13px;
  color: #ffffff;
  border-radius: 0.3rem;
  position: relative;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.thememan .menu-aside .menu-link:hover {
 
  color: #000000;
 
}

.thememan .menu-aside .menu-item.active .menu-link {
  background-color:#ffffff ;
  color: #000000;
}
.thememan .main-header {
  padding-left: 3%;
  padding-right: 3%;
  min-height: 72px;
  background-color: rgb(0, 0, 0);
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-bottom: 1px solid #eee;
}
.thememan .content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
  background-color: #000000;
}
.thememan .card-title {
  color: #fcfcfc !important;
}
.thememan .block-title {
  color: #fcfcfc !important;
}
.thememan .themebtn1 {
  color: #000000;
  border-radius: 17px;
}
.thememan .themebtn1 i {
  color: #ffffff;
  /* border-radius: 10px; */
  background: #000000;
  padding: 5px;
border-radius: 50%;
width: 25px;
height:25px;
}

.dtpick .form-control{
  /* background-color: #f4f5f9; */
  border: 2px solid #5f5f61;
  /* font-size: 13px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  color: #4f5d77;
  width: 100%;
  border-radius: 4px;
  height: 41px; */
}
 .themebtn2 {
  color: #ffffff;
  background: #000000;
  border-radius: 17px;
}
 .themebtn2 i{
  color: #000000;
  background: #ffffff;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height:25px;
}
.thememan .themebtn1 .fa {
  margin-left: 18px;
}
.form-label {
  font-size: 17px !important;
  color: #000000;
}
.modal-content {
  position: relative;
  /* display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto; */
  background-color: #F3F8FC;
  /* background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0; */
}
.modal-content .card {
 
  background-color: #F3F8FC;

}
#avatar{
  position: relative;
  width: 100px;
}
.proeditbtn {
  position: absolute;
  top: 0;
  right:0;
  background: transparent;
    border: none;

}
 .themebtn2 .fa {
  margin-left: 0px;
  margin-right: 18px;
}
.searchuniversitiesandcourses {
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 99999;
  max-width: 630px;
  border: 2px solid #bce3c9;
  border-top: 1px solid #f2f2f2;
}

.loadingPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  /* background-color: #00000087; */
  background-color: white;
}

.tabBtn1 {
  background-color: #eee;
  border: 0;
  border-color: initial;
  border-radius: 4px;
  color: #444;
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  margin: 0 4px;
  padding: 10px 8px;
  transition: 0.2s;
}

.tabBtn1.active {
  color: #3bb77e;
  background-color: #fde1bd;
  transform: translateY(-3px);
  /* transition: 0.2s; */
}
.tabBtn1.active {
  color: #3bb77e;
  background-color: #fde1bd;
  transform: translateY(-3px);
  /* transition: 0.2s; */
}
.search-sec {
  /* background-color: #CF9512;
  padding: 0;
  border-radius: 50px;
  border: 0px;
  width: 100%;
  display: inline-flex;
  color: #fff !important;
  width: 280px;
  padding-left: 30px; */
  position: relative;
}

.searchuniversitiesandcourses ul li {
  cursor: default;
  display: block;
  margin-right: 0;
  padding-left: 17px;
  line-height: 35px;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  border-bottom: 1px solid #ccc;
}

.bottomLogo img {
  width: 200px;
}

footer .hotline {
  min-width: 300px !important;
}

.searchuniversitiesandcourses ul li:hover {
  /* padding-left: 17px;
    display: block; */
  background: #f2f2f2;
}

button.react-share__ShareButton {
  margin: 10px;
}
.footerBannerImg {
  margin: auto;
}

.footerBannerImg img {
  max-width: 100%;
  margin: auto;
  display: block;
}
.iconSize {
  font-size: 18px !important;
}

.unishareIcon {
  text-align: center;
}

.shareLinkInModel {
  padding: 20px;
}

.searchuniversitiesandcourses ul {
  text-align: start;
}

.homePageSlider {
  position: relative;
}

.homePageSlider .slider-content {
  position: absolute;
  top: 50%;
  left: 6%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.homePageSlider img {
  display: block;
  height: 100%;
  width: auto !important;
  /* object-fit: cover; */
  /* border-radius: 30px; */
}

.homePageSlider .slider-content form {
  background-color: #fff;
  max-width: 450px;
  border-radius: 50px;
}

.homePageSlider .slider-content form input {
  border: 0;
  border-radius: 50px 0 0 50px;
  padding-left: 58px;
  /* background: url("../imgs/theme/icons/icon-plane.png") no-repeat 25px center; */
}

.homePageSlider {
  min-height: 538px;
  height: 90vh;
  border-radius: 30px;
  background-size: cover;
  background-position: center center;
}

.homePageSlider .slider-content form button {
  border: 0;
  border-radius: 50px;
}

.homePageSlider1 .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #3bb77e;
}

.homePageSlider1 .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.homePageSlider1.appslider .slick-dots {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}


.homePageSlider1 .slick-dots li button:before {
  font-family: "slick";
  font-size: 17px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.homePageSlider1.appslider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 10px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.homePageSlider1.appslider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.homePageSlider1.appslider .slick-dots li button:before {
  font-family: "slick";
  font-size: 8px;
  line-height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 11px;
  height: 7px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homePageSlider1.appslider .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #4f4f4f;
}
.homePageSlider1.appslider img {
  max-width: 100%;
  border-radius: 10px;
}
.sliderM {
  padding-left: 12px;
  padding-right: 12px;
}

.productPage__left {
  position: relative;
}

.productPage__container {
  overflow-x: none;
}

.productPage__container {
  position: relative;
  display: flex;
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  background: #e4e4e4;
}

.productPage__left {
  /* height: 100vh; */
  top: 0px;
  /* width: 60vw; */
  display: flex;
  padding: 20px;
  margin-top: 9vh;
  position: sticky;
  /* background-color: #e4e4e4; */
  text-align: center;
  align-items: center;
}

.productPage__sideImagesContainer {
  height: fit-content;
  background-color: white;
  border: none;
  justify-content: center;
}

.productPage__right {
  width: 55%;
  margin: 10px;
  margin-top: 13vh;
  overflow-y: scroll !important;

  background-color: white;
  margin-right: 39px;
}

.productPage__right::-webkit-scrollbar {
  display: none;
}

.sideImage {
  width: 50px;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #e4e4e4;
  background-position: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sideImageActive {
  width: 50px;
  height: 60px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  /* border: 2px solid #3bb77e; */
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.table {
  overflow: auto;
}

.sideImage img {
  /* margin: auto;
  width: 100%;
  height: 50px;
  display: block; */
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.sideImage:hover {
  border: none;
  box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -webkit-box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -moz-box-shadow: 2px 2px 14px -1px rgba(228, 228, 228, 0.66);
}
.sideImageActive img {
  /* margin: auto;
  width: 100%;
  height: 50px;
  display: block; */
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.sideImageActive:hover {
  border: none;
  box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -webkit-box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -moz-box-shadow: 2px 2px 14px -1px rgba(228, 228, 228, 0.66);
}

.productPage__displayImageContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 101.5%;
  background-color: white;
  margin-left: 18px;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.product_page_top {
  background-color: #e4e4e4;
}

.productPage__displayImage {
  max-width: 80%;
  height: 80%;
  /* max-height: 400px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .imgViewS img:first-child{
  max-height: 400px;
    max-width: 100%;
    width: auto !important;
    margin: auto;
} */
.imgViewS1 {
  max-height: 400px;
  max-width: 100%;
  width: auto !important;
  margin: auto;
}

child {
  border: 2px solid orange;
}

.imgViewS {
  color: red;
  background-color: #fff;
}

.tabBtn {
  background-color: #eeeeee;
  font-size: 15px;
  margin: 0 10px;
  margin-left: 10px;
  color: #444;
  border-radius: 4px;
  padding: 15px 24px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 0;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  line-height: 1;
}
.tabBtn:hover {
  color: #3bb77e;
  background-color: #fde1bd;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* padding: 15px; */
  /* border: none; */
}
.tabBtn.active {
  color: #3bb77e;
  background-color: #fde1bd;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* padding: 15px;
  border: none; */
}

.menubtn {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid transparent;
  background-color: transparent !important;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  letter-spacing: 0.5px;
}
.card-header1 {
  padding: 1.3rem 1.3rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(222, 226, 230, 0.7);
}

.dataTables_paginate.paging_simple_numbers.category {
  float: left;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: #e9ecee !important;
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li {
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189d !important;
  background: #e9ecee !important;
  border-radius: 0.5rem;

  color: var(--bs-pagination-color) !important;
  font-size: 16px;
  margin: 3px;
  display: inline-block;
}

/* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  

} */
.dataTables_paginate.paging_simple_numbers.category li:last-child {
  background: #e9ecee !important;
  color: var(--bs-pagination-color) !important;
}
.fw-semibold .text-gray-600 tr td {
  color: var(--bs-pagination-color) !important;
}

.next.disabled {
  pointer-events: none;
}
.previous.disabled {
  pointer-events: none;
}

.dataTables_paginate.paging_simple_numbers.category li.selected {
  color: #fff !important;
  background: #3bb77e !important;
}

.dataTables_paginate.paging_simple_numbers.category li:hover {
  background: #3bb77e !important;
}

.image-item {
  display: flex;
  margin: 10px 0;
}

.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.coustomSelect {
  height: 35px;
}
.coustomSelect .coustomSelect__input {
  height: 35px;
  color: #000000 !important;
}

.coustomSelect__input-container {
  color: #000000 !important;
}
.coustomSelect .css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  /* grid-template-columns: 0 min-content; */
  /* margin: 2px; */
  /* padding-bottom: 2px; */
  /* padding-top: 2px; */
  color: #000000;
  box-sizing: border-box;
  height: 35px;
}

.btn-light {
  color: #000000;
  margin-left: 6px;
}

.homePop img {
  width: 100%;
}

.changeEmail {
  margin-top: 30px;
}
.single-thumbnail img {
  border-radius: 15px;
  overflow: hidden;
  max-width: 100%;
}
.bolgBox img {
  max-width: 100%;
}

.post-thumb img {
  max-width: 100%;
}
.minHeCate {
  height: 350px;
  overflow: auto;
}

.coustomP {
  padding: 6px 10px !important;
}

.redBtn {
  background-color: red;
}
.activeCate {
  border: 1px solid #bce3c9 !important;
  -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

@media only screen and (max-width: 1024px) {
  .homePageSlider {
    /* min-height: 350px; */
    height: 350px;
  }
}

@media only screen and (max-width: 1400px) {
  .homePageSlider {
    height: 350px;
  }
  .homePageSlidernewapp {
    padding: 5px;
    padding-bottom: 15px;
    /* height: 370px; */
  }
}

@media only screen and (max-width: 992px) {
  .productPage__container {
    flex-direction: column;
  }
  .productPage__left {
    width: 97vw;
  }
  .detail-info h1 {
    font-size: 25px;
  }
  .homePageSlider {
    min-height: 340px;
    height: 240px;
  }
 
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
}
@media only screen and (max-width: 768px) {
  .productPage__container {
    flex-direction: column;
  }
  .productPage__left {
    width: 97vw;
  }
  .detail-info h1 {
    font-size: 25px;
  }
  .homePageSlider {
    min-height: 265px;
    height: 180px;
  }
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
  #avatar {
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .hero-slider-1 .single-hero-slider .display-2 {
    font-size: 32px;
  }
  .detail-info h1 {
    font-size: 21px;
  }
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
  .homePageSlider {
    min-height: 190px;
    height: 190px;
  }
  .homePageSlider img {
    display: block;
    height: 100%;
    width: auto !important;
    /* object-fit: cover; */
    border-radius: 1px;
}
#avatar {
  text-align: center;
}
.popular-categories h1 {
  font-size: 30px;
}
}



.dropButton {
  color: black !important;
  background-color: #ffffff !important;
  padding: 10px 10px !important;
}

.dropButton.dropdown-toggle:after {
  position: absolute;
  top: 50%;
  color: #0c0c0c;
  right: 0px;
}

.modalBoXContaint {
  width: 100%;
  overflow: auto;
  margin-bottom: 80px;
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 2px solid #ff9696;
  background: #fff;
}

.faq-section {
  background: #fdfdfd;
  min-height: 100vh;
  padding: 10vh 0 0;
}
.faq-title h2 {
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}
.faq-title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: #e91e63;
  bottom: -25px;
  margin-left: -30px;
}
.faq-title p {
  padding: 0 190px;
  margin-bottom: 10px;
}

.faq {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.faq .card {
  border: none;
  background: none;
  border-bottom: 1px dashed #cee1f8;
}

.faq .card .card-header {
  padding: 0px;
  border: none;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
  background: rgba(233, 30, 99, 0.1);
  padding-left: 10px;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faq .card .card-header .faq-title .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 14px;
  float: left;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  text-align: center;
  background: #e91e63;
  color: #fff;
  font-size: 12px;
  margin-right: 20px;
}

.faq .card .card-body {
  padding: 30px;
  padding-left: 35px;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #6f8ba4;
  line-height: 28px;
  letter-spacing: 1px;
  border-top: 1px solid #f3f8ff;
}

.faq .card .card-body p {
  margin-bottom: 14px;
}

.searchDesplay {
  display: none;
}
.puan p span{
  text-transform: uppercase;
  color: #000000;
}
.contecttest span{
  color: #000000;
}
.mtrp a {
  color: #000000;
}
.pt .ram .nav-tabs .nav-link {
  color: #000000;
  font-weight: 700;
}
.puan p strong{
 font-weight: 700;
 color: #000000;
}
.mtrp li:nth-child(odd){
  background: #dadada;
}
.mtrp li{
 padding: 5px;
}
@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }
  .searchDesplay {
    display: block !important;
    width: 100%;
    position: absolute;
    top: 45px;
    background: #fff;
    padding: 10px 10px;
    z-index: 9;
  }
  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }

  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
}

.moredate {
  padding: 10px !important;
}

.itemside .left img {
  height: 60px !important;
}

.bticon i {
  padding-right: 15px;
}
/* .main-menu > nav > ul > li ul.mega-menu {
  width: auto !important;
} */
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-22 {
  min-width: 250px;
}

.sideNav-overlay {
  background: rgba(0, 0, 0, 0.57);
  height: 100%;
  left: 0;
  opacity: 1;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.3, 1);
  width: 100%;
  z-index: 9;
}

.cateheading {
  font-size: 20px;
}

.btn-success2 {
  color: #fff;
  background-color: #198754 !important;
  border-color: #198754 !important;
  margin-left: 5px;
}

.verificationRed {
  text-align: center;
  justify-content: center;
}

.verificationRed p {
  color: red;
  font-size: 16px;
}

.package {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.package__item {
  height: 100%;
  transition: all ease-in-out 0.3s;
}
/* .package__item:nth-child(1) .package__header {
  background-color: #eea726;
} */

/* .package__item:nth-child(2) .package__header {
  background-color: #459bba;
} */
/* .package__item .package__header {
  background-color: #459bba;
} */
.package__item .package__header .package__name {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  color: white;
}

/* .package__item:nth-child(3) .package__header {
  background-color: #78a87f;
} */

.package__item .package__body {
  background-color: white;
  padding: 1rem;
  min-height: 82%;
}

.package__item .package__body .package__price-container {
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
}

.package__item .package__body .package__price {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  position: relative;
}

.package__item .package__body ul {
  margin-top: 1rem;
}

.package__item .package__body ul li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 600;
  color: #31363c;
}

.package__item .package__header {
  background-color: #ff8300;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.package__item .package__body .package__price-container--column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column {
  display: grid;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column--2 {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}
.package__item
  .package__body
  .package__price-container--column
  .package__column--2
  .package__column-title {
  background-color: #5d9064;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  .package__column-title {
  color: white;
  background-color: #78a87f;
  font-weight: 600;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  [class^="package__column"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  .package__column-price--bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.package__item .package__footer button {
  background-color: #ff8300;
  color: white;
  padding: 8px 0;
  width: 100%;
  border: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.3s;
  font-weight: 600;
}

.package__item .active button {
  background-color: #78a87f;
}

/* .package__item:nth-child(3) .package__footer button:hover {
  background-color: #659c6d;
}

.package__item:nth-child(3) .package__footer button {
  background-color: #78a87f;
} */

.ril_id_box {
  width: 500px;
  position: relative;
}

.ril_di_card {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 100;
}

.ril_di_card_left {
  width: 50%;
  position: relative;
}

.ril_di_card_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 50%;
  font-weight: 100;
  font-family: poins;
  font-size: 21px;
  /* color: #fe5928; */
}

.ril_img {
  position: absolute;
  width: 117px;
  height: 117px;
  border-radius: 50%;
  right: 5px;
  bottom: -55px;
}

.ril_logo {
  position: absolute;
  top: 0;
  right: 0;
}

.watermark {
  opacity: 0.2;
  /* color: BLACK; */
  position: absolute;
  top: auto;
  right: 0;
  /* font-size: 65px; */
  transform: rotate(45deg);
}

.ril_di_card_top {
  position: absolute;
  top: 0;
  text-transform: capitalize;
  font-size: 31px;
  color: #ff5829;
}

.rilIDText {
  font-size: 20px;
  font-weight: 600;
  text-align: justify;
  padding: 38px;
}

.information-title {
  border-bottom: 3px solid #7d7d7d;
  background-color: #7d7d7d;
  color: #ffffff;
}

.information-title {
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.recent-post .media-category {
  color: #000000;
}
.recent-post .media-heading a:hover {
  color: #000000;
}
.widget .recent-post .media-meta a:hover {
  color: #000000;
}

.media-object {
  display: block;
}

.recent-post .media-body {
  font-size: 16px;
  line-height: 18px;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}

.media-body {
  width: 10000px;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.profile_ulv {
  padding: 9px;
  background-color: #fff;
  /* border-radius: 10px; */
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  margin-bottom: 2px;
  /* margin-right: 10px; */
  height: 68px;
}
.profile_ul {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  margin-bottom: 10px;
}

.selectedSize {
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  background: #000000;
  color: #fff;
  border-radius: 15px;
}
.unselectedSize {
  border: 1px solid rgb(123, 123, 123);
  text-align: center;
  border-radius: 15px;
}

.pDetails .product-price {
  padding-top: 5px;
}

.pDetails .product-price span {
  font-size: 18px;
  font-weight: bold;
  color: #0D6EFD;
}
.pDetails .product-price span.old-price {
  font-size: 14px;
  color: #adadad;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.react-datepicker__month-container {
  position: relative;
  z-index: 999999;
}
.text-green-700{
  color: #146C43;
  font-weight: 700;
}
.text-red-700{
  color: #0D6EFD;
  font-weight: 700;
}

.form-group select {
  background: #fff;
  border: 1px solid #ececec;
  height: 64px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}

.registersuccessBox{
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
  max-width: 350px;
  background-color: #fed4cb;
  /* min-height: 230px; */
}
.registersuccessBox h5{
  color: #088d4f;
  font-size: 15px;
}
.registersuccessBox h6{
  /* color: #088d4f; */
  font-size: 12px;
}

.card-body{
  /* overflow: auto; */
  width: 100%;

}

.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars {
  display: flex;
}

.star {
  font-size: 24px;
  margin: 0.2rem;
}

.achieved {
  color: yellow;
}

.unachieved {
  color: gray;
}

.menu-aside{
  height: 80vh;
  overflow: auto;
}


/* range */
.range{
  border: 2px dotted black;
  outline: 5px solid rgb(76, 125, 158);
  position: relative;
  background: rgb(76, 125, 158);
}
.range_star{

  margin-top: -25px;
  position: relative;
  z-index: 9;
}
td{
  color: #000000;
}
.range_star img{
  height: 35px;
  width: 35px;
}

.profile_ul1 {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
}
.range_star img:hover{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
.star_p{
  font-size:12px;
}

.logomaImg1{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.logoma {
  width: 110px;
  margin: auto;
  position: relative;
}
.logomaImg {
  position: absolute;
  top: 17px;
  left: 21px;
  width: 69px !important;
  height: 73px;
}
.logoma img{
  width: 100%;
  
}
.logoman {
  width: 70px;
}

.logoman img{
  width: 30%;
  position: absolute;
  margin-left: -15%;
  margin-top: 5%;
}
.badge{
  z-index: 1;
  position: relative;
  margin-top: 20px;
}
.name_box {
  border: 1px solid red;
  padding: 3px 34px 3px 40px;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.topSellerRankDiv {
  position: absolute;
  top: -18px;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.TopsellerRank {
  display: flex;
  height: 35px;
  background-color: #f39a56;
  border-radius: 50%;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  width: 35px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  border: 3px solid #fff;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0px 4px 9px rgba(255, 170, 46, 0.4);
}
.crown::before {
  margin-right: 0px;
  content: '';
  background: url("./taj.png");
  position: absolute;
  background-repeat: no-repeat;
  top: -16px;
  width: 26px;
  height: 24px;
  background-size: contain;
}

.prodetailsbox{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}
.prodetailsbox1{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
}
.prodetailsbox h1{
 font-size: 20px;
}
.newfaq .badge{
  margin-top: 2px;
}

.licebox {
  background: #ffffff;
  padding: 20px;
}
.liceboxnew {
  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  border-radius: 5px;
  overflow:auto;
}

.liceninput{
  height: 30px;
}
.liceninput input{
  height: 30px;
}

.lcbtn {
  padding: 5px !important;
}

.chrtitmess {
  position: relative;
}
.chrtitmess .pro-count.blue {
  background-color: #a92298;
  /* background-color: #0D6EFD; */
  position: absolute;
  right: -11px;
  top: -5px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.fa {
  margin-left: 5px;
}

.btncccc{
  color: #198754 !important;
}

.oncartl {
  border: 4px solid white;
  border-radius: 35px;
  box-shadow: 0px 6px 15px 1px pink;
  height: 108px;
}

.float-right{
  float: right;
}

.rui.search-modal-header {
  padding-top: 10px !important;
}
.rui.search-modal-header {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ffffff;
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: background-color 200ms linear;
}
.rui.search-modal-header {
  z-index: 150;
}


.rui.search-modal-input {
  width: 96%;
  position: relative;
  border-bottom: 1px solid #999999;
}

.rui.search-modal-input .search-submit {
  right: 10px;
  font-size: 12px;
  position: absolute;
  top: 42px;
  color: #666666 !important;
  cursor: pointer;
  background: #e6e6e6;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 20px !important;
  border-style: none !important;
  transition: background-color 200ms linear;
}

.rui.search-modal-close {
  right: 10px;
}
.rui.search-modal-close {
  position: absolute;
  top: 10px;
  z-index: 1051;
}
.search-modal .rui.btn-default.icon-only {
  position: fixed;
  right: 10px;
}
.rui.btn-default.icon-only {
  color: #505558;
  background-color: #ffffff;
  border-color: #ffffff;
}
.rui.btn.icon-only {
  border: none;
  font-size: 10px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  color: #505558;
  background-color: #ffffff;
  box-shadow: none;
}

.buttons{
  border-radius: 50px;
  border: 1px solid green;
  background-color: navajowhite;
  width: 80px;
  height: 40px;

}  
.button3{
  border-radius: 50px;
  border: 1px solid green;
  background-color: white;
  width: 130px;
  height: 40px;

}

.prdehr{
  color: orange;
  height: 1px !important;
  opacity: 1;
}

button.addcrtbtn.btn.btn-success {
  width: 100%;
  background: #0D6EFD !important;
  border-radius: 35px;
  box-shadow: 0px 6px 20px 0px #ff952854;
}

.checkoutbx{
  background: #ffd9b3;
}

.col-lg-1-5.primary-sidebar.sticky-sidebar input{
  width: auto;
}

.wrap-collabsible-filt {
  padding: 2px 10px 2px 10px;
  text-align: center;
  float: left;
  position: relative;
  background-color: #FD724D;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 7px;
}
.lbl-toggle-filt {
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
  text-align: left;
  color: #000;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

.lbl-toggle-filt::after {
  content: '+';
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.7rem;
  transition: transform 0.2s ease-out;
  transform: rotate(45deg);
  margin-top: -3px;
}

.filter-head {
  margin-top: 20px;
  font-family: sans-serif;
}

.filter-head {
  font-size: 14px;
  color: #717171;
  display: inline-block;
  font-weight: 500 !important;
}
.vertical-div {
  width: 10%;
  display: inline-block;
  margin: 2px 20px 5px 20px !important;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: black;
}

.filter-head-clr {
  color: #e2785a;
  cursor: pointer;
}

.blogtitlt{
  font-size: 14px;
}

form.hin {
 
  float: inline-end;
}

/* Header Start */

 /* Media Query */
 /* * body{
	background-color: antiquewhite;
} */
@media only screen and (max-width: 575px) {
 .our{
    	display: none;
    	
  }
  .per{
  	margin-top: 110px;
  }
  
}


.abpage .per{
  /* font-family: Lucida Console; */
  font-weight: lighter;
  font-size: 28px;
  line-height: 39px;
  color: dimgray;
}
.per-1{
	    font-size: 42px;
}
.on{
	border: 2px solid green;
}





  

*{
	margin:0;
	box-sizing: border-box;
}
:before,:after{
	box-sizing: border-box;
}
.container{
	max-width: 1200px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
.v-center{
	align-items: center;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
a{
	text-decoration: none;
}
/* header */
.header{
	display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:15px;
}
.header .item-left{
	flex:0 0 17%;
}
.header .logo a{
	font-size: 30px;
	color:#000000;
	font-weight: 700;
	text-decoration: none;
}
.header .item-center{
	flex:0 0 66%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right a{ 
     text-decoration: none;
     font-size: 16px;
     color:#555555;
     display: inline-block;
     margin-left: 25px;
     transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 25px;
}
.header .menu > ul > li > a{
	font-size: 15px;
	font-weight: 500;
	color:#000000;
	position: relative;
	text-transform: capitalize;
	transition: color 0.3s ease;
  padding-bottom: 5px;
}
.header .menu > ul > li > a:hover{
  border-bottom: 2px solid red;

}

.profile_ul .card-body {
  overflow:hidden
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
}
@media(min-width: 992px){
.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}
.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a{
	display: inline-block;
	padding: 10px 0;
	font-size: 15px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
   line-height: 1;
   display: block; 
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
	padding:10px 0;
	display: inline-block;
	font-size: 15px;
	color:#555555;
	transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu.mega-menu{ 
    left: 50%;
    transform: translateX(-50%);	
}

.header .menu > ul > li .sub-menu.mega-menu-column-4{
  max-width: 1100px;
  width: 100%; 	
  display: flex;
  flex-wrap: wrap;
  padding:20px 15px;
  /* flex-direction: column; */
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
  flex:0 0 25%;
  padding:0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4   .list-item .title{
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  /* line-height: 0.8; */
  font-weight: 700;
  padding: 1px 0;
  margin-bottom: 0px;
  padding: 5px;
}

.sub-menu.mega-menu.mega-menu-column-4 h5{
  margin-bottom: 10px;
}
.sub-menu.mega-menu.mega-menu-column-4 .subsubbg h5{
  margin-bottom: 10px;
  padding-left: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
	max-width: 100%;
	width: 100%;
	vertical-align: middle;
	margin-top: 10px;
	height: 300px;
	object-fit: cover;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	color:#ea4636;
}

.subsubbg {
  /* min-height: 266px !important; */
  /* background-color: #7E9BC6; */
  background-color: #f1f6fd;
}
.subcatactive {
  /* min-height: 266px !important; */
  background-color: #f1f6fd;
}
.subsubbg li {
  line-height: 1.7;
  margin: 0px !important;
  padding: 1px !important;
  padding-left: 15px !important;
}
.subsubbg li a{
 color: #000;
}
/* banner section */
/* .banner-section{
  background-image: url('../img/banner.jpg');
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
} */
.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}

/*responsive*/
@media(max-width: 991px){

	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	/* .header .item-left,
	.header .item-right{
		flex:0 0 auto;
	} */
	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 320px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	   transform: translate(0%);	
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > a{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	.header .menu > ul > li > a i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	    height: 50px;
		width: 50px;
		border-left: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;	
		font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:15px;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	    opacity:1;
		transform: translateX(0%);	
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	    opacity:0;
		transform: translateX(100%);	
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	  visibility: visible;
	  opacity:1;	
	}
}
.header{
	background-color: #ededed;
	/* background-color: #c8dcff; */
}

table tr {
  /* padding: 10px 20px;
  border: 1px solid #ececec; */
  vertical-align: middle;
  text-align: center;
}


.first1{
					margin-top: -66px;
}
.our{
	background-color: #e6ffe6;
	font-weight: 550;
	font-size: 23px;
	color: #4b339b;
	font-style: italic;
}

.faq-title2{
  background-color: #fdf4eb;
}

.pic{
	background-image: url('https://cdn.pixabay.com/photo/2017/09/15/19/41/businessman-2753324_960_720.jpg');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}
.abpage .pic{
	background-image: url('AboutUs1.png');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}

.pic{
	background-image: url('Compliance.png');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}
@media only screen and (max-width: 575px) {
  .abpage .pic{
    background-image: url('AboutUs1.png');
    background-repeat: no-repeat;
    width: 100%;
    height:175px;
    background-size: cover;
  }
  
  .pic{
    background-image: url('Compliance.png');
    background-repeat: no-repeat;
    width: 100%;
    height:160px;
    background-size: cover;
  }

  .fiv1 {
    font-style: italic;
    color: #684b8f;
    font-size: 14px;
}

.page-header h1{
  font-size: 20px;
}
   
 }
.compliance .cet{
	font-family: Verdana;
	line-height: 25px;
	color: #23566D;
	font-size: 14px;
	cursor: pointer;
}
.compliance i{
	color: sandybrown;
}

.compliance .toy{
	border: 1px solid gray;
}

.compliance .our {
  background-color: antiquewhite;
  width: 600px;
  font-family: arial;
  font-size: 20px;
  font-weight: 500;
  margin-top: -80px;
}

.mheader {
  position: fixed;
  top: 0;
  width: 100%;
  /* z-index: 99; */
  z-index: 3;
}
.sortvs {
  background-color: white;
  /* box-shadow: 1px 1px 8px 2px pink; */
  padding: 10px;
  width: auto;
  color: darkslategray;
  border-radius: 25px;
}
.sortvs input {
  width: auto;
}

 
.menu-aside .fa{
  font-size: 15px;
  margin-right: 10px;
}

.row.product-grid .product-grid {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



/* user deshboard */


.card1 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card1 {
  border-left: 20px solid rgb(24, 130, 178);
  border-radius: 30px;

}

.card2 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card2 {
  border-left: 20px solid rgb(69, 178, 150);
  border-radius: 30px;

}

.card3 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card3 {
  border-left: 20px solid rgb(250, 149, 50);
  border-radius: 30px;

}

.butt {
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid #afadad;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
  color: #afadad !important;
  margin-left: 55px;
}



.card4 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
   height: 150px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card4 {
  border-left: 20px solid rgb(184, 135, 229);
  border-radius: 30px;

}

.buuton {
  margin-left: 800px;
  border-color: blue;
  background-color: white;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;

}


.card5 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 150px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card5 {
  border-left: 20px solid green;
  border-radius: 30px;

}


.img1 {
  position: absolute;
  width: 80px;
  margin-left: 80px;
  margin-top: -50px;
}

.i1 {
  margin-left: 190px;
}


.img2 {
  position: absolute;
  width: 80px;
  margin-left: 55px;
  margin-top: -50px;
}

.img3 {
  position: absolute;
  width: 80px;
  margin-left: 175px;
  margin-top: -50px;
}

.businessRecord th {
  padding: 10px 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.ant-card-wider-padding .ant-card-body {
  padding: 24px;
}

.businessRecord td {
  padding: 10px 0px;
  font-weight: bold;
}
td{
  text-align: center;
}

/* prfile page  */

#puan {
  width: 100%;
  padding: 5px;
}

.pt .puan {
  background: rgb(255, 255, 255);
  box-shadow: 0px 5.12253px 25.6126px rgba(255, 149, 40, 0.25);
  padding: 5px;
  border-radius: 35px;
}


.pt .puan #avatar img {
  width: 80px;
  height: 80px;
  border-radius: 215px;
}

.pt .puan #name {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 18px;
  position: absolute;
  margin: -91px 112px;
}

.pt .puan #whatpuan {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: rgb(244, 222, 54);
  font-size: 18px;
  position: absolute;
  margin: -65px 112px;
}

.pt .puan #desc {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 14px;
  position: absolute;
  margin: -41px 112px;
}

.pt  .ranktitle {
  background-color: #ccc;
  font-size: 8px;
  height: 55px;
  width: 55px;
  padding: 20px 5px;
  border-radius: 30px;
}

.pt  .button1 {
  color: rgb(24, 144, 255);
  background-color: rgb(186, 231, 255);
  border: 1px solid rgb(186, 231, 255);
  border-radius: 30px;
}

.pt h6 {
  font-size: 14px;
}

.pt .rounded-circle {

  height: 90px;
  width: 80px;
  border: 6px solid rgb(135, 208, 104);
  border: none !important;
  padding: 20px 15px;
  color: rgb(135, 208, 104);
  ;

}
.nav-item img.rounded-circle {
  border: none !important;
}
.pt .select {
  width: 200px;
  border-radius: 30px;
  height: 38px;
}

.pt .bor {
  border-right: 2px solid;
}

.pt .ram {
  border-bottom: 2px solid;
  position: relative;
}
.pt .bot1{
  border: 1px solid #eee;
  background-color: #eee;
  margin-left: 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #ccc;
  
  
  }
  .pt .d-flex{
  float: right;
}
.pt .bot2{
       background-color: transparent;
       border:1px solid rgb(204, 204, 204);
       color: rgb(204, 204, 204);
       border-radius: 20px;
       height: 35px;
       width: 70px;
}
.pt .image{
  height: 250px;
  width: 250px;
}
.pt .save {
  background-color: #00c2ff;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(31 31 31);
  border-radius: 20px;
  height: 35px;
  width: 140px;
  margin-left: 35%;
  font-size: 15px;
  font-weight: 700;
}


.pt .pixDiv {
  border: 2px;
  width: 138px;
  height: 178px;
  margin: 0 auto 30px;
  display: block;
  position: relative;
  justify-content: flex-end;
  flex-direction: column;
  border-style: dashed;
  border-radius: 20px;
  border-color: #c4c4c4;
  box-shadow: 0px 10px 20px 0px #c4c4c4;
}

.pt .pixDiv .pixDocumentTitle {
  border-top: 2px dashed #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  color: #cbcbcb;
  font-size: 14px;
  font-family: sans-serif;
}

.pt .prm .nav-tabs .nav-link{
margin: 0px;
padding-left: 15px !important;
background: #fff;
}
.pt .prm .nav-tabs .nav-link.active{
margin: 0px;
padding-left: 15px !important;
background: #fde1bd;
border-bottom: 5px solid #ff983d;
}

.jnbt{
  margin-bottom: 30px;
}

.contecttest h4{
  font-size: 14px;
  /* margin-bottom: 12px; */
  color: #000000;
}
table thead th {
color: #000000;
}
.contecttest h2{
  font-size: 20px;
  margin-bottom: 12px;
}


.myLabelComponentInSvg {
  height: 70px;
  width: 180px;
  margin: 10px;
}

 /*graph  */

 .p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart-line-down {
  margin: 0 auto;
  height: 20px;
  width: 1px;
}

.p-organizationchart-table>tbody>tr>td {
  text-align: center;
  vertical-align: top ;
  padding: 2px 5px !important;
  /* padding: 0 0.75rem; */
}
.p-organizationchart-table {
  border-spacing: 0;
  border-collapse: separate;
  margin: 0 auto;
}
.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
}
.p-organizationchart-table>tbody>tr>td {
  text-align: center;
  vertical-align: top;
  /* padding: 0 0.75rem; */
}
.organizationchart-demo .card {
  overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
  padding: 0;
  border: 0 none;
}

.organizationchart-demo .p-organizationchart .node-header, .organizationchart-demo .p-organizationchart .node-content {
  padding: .5em .7rem;
}

.topheadBox img{
  width: 100%;
}

.organizationchart-demo .p-organizationchart .node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
  text-align: center;
  border: 1px solid #495ebb;
}

.organizationchart-demo .p-organizationchart .node-content img {
  border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
  background-color: #7247bc;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
  background-color: #a534b6;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
  background-color: #e9286f;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  
.card1 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}
.cardred {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card1 {
  border-left: 20px solid rgb(24, 130, 178);
  border-radius: 30px;

}

.card2 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card2 {
  border-left: 20px solid rgb(69, 178, 150);
  border-radius: 30px;

}

.card3 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card3 {
  border-left: 20px solid rgb(250, 149, 50);
  border-radius: 30px;

}

.butt {
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid #afadad;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
  color: #afadad !important;
  margin-left: 55px;
}



.card4 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
   height: 110px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card4 {
  border-left: 20px solid rgb(184, 135, 229);
  border-radius: 30px;

}

.buuton {
  margin-left: 800px;
  border-color: blue;
  background-color: white;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;

}


.card5 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 110px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card5 {
  border-left: 20px solid green;
  border-radius: 30px;

}

.img1 {
  position: absolute;
  width: 40px;
  margin-left: 80px;
  margin-top: -50px;
}

.i1 {
  margin-left: 190px;
}


.img2 {
  position: absolute;
  width: 40px;
  margin-left: 55px;
  margin-top: -50px;
}

.img3 {
  position: absolute;
  width: 40px;
  margin-left: 175px;
  margin-top: -50px;
}


}


 


 
 
.register {
	background: linear-gradient(to right, rgba(57,49,175,1) 0, rgba(0,198,255,1) 100%);
	margin-top: 3%;
	padding: 3%;
	overflow: hidden;
}
.register .register-form {
	padding: 10%;
	margin-top: 10%;
}
@media (max-width: 991px){
	.register .register-form {
		margin-top: 16%;
	}
}
@media (max-width: 667px){
	.register .register-form {
		margin-top: 20%;
	}
  .dtpick .form-control {
    /* background-color: #f4f5f9; */
    /* border: 2px solid #5f5f61; */

    padding-left: 3px !important;
}
}
.register .nav-tabs {
	margin-top: 3%;
	border: none;
	background: #0062cc;
	border-radius: 1.5rem;
	width: 28%;
	float: right;
}
@media (max-width: 991px){
	.register .nav-tabs {
		width: 33%;
		margin-top: 8%;
	}
}
.register .nav-tabs .nav-link {
	padding: 2%;
	height: 34px;
	font-weight: 600;
	color: #fff;
	border-top-right-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
	border: none;
}
.register .nav-tabs .nav-link.active {
	width: 100px;
	color: #0062cc;
	border: 2px solid #0062cc;
	border-top-left-radius: 1.5rem;
	border-bottom-left-radius: 1.5rem;
}
.register-left {
	text-align: center;
	color: #fff;
	margin-top: 4%;
}
.register-left input {
	border: none;
	border-radius: 1.5rem;
	padding: 2%;
	width: 60%;
	background: #f8f9fa;
	font-weight: bold;
	color: #383d41;
	margin-top: 30%;
	margin-bottom: 3%;
	cursor: pointer;
}
.register-left img {
	margin-top: 15%;
	margin-bottom: 5%;
	width: 25%;
	animation: mover 1s infinite alternate;
}
.register-left p {
	font-weight: lighter;
	padding: 12%;
	margin-top: -9%;
}
.register-right {
	background: #f8f9fa;
	border-top-left-radius: 10% 50%;
	border-bottom-left-radius: 10% 50%;
}
@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-20px);
	}
}
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-20px);
	}
}
.btnRegister {
	float: right;
	margin-top: 10%;
	border: none;
	border-radius: 1.5rem;
	padding: 2%;
	background: #0062cc;
	color: #fff;
	font-weight: 600;
	width: 50%;
	cursor: pointer;
}
.register-heading {
	text-align: center;
	margin-top: 8%;
	margin-bottom: -15%;
	color: #495057;
}
@media (max-width: 991px){
	.register-heading {
		font-size: 1.5rem;
	}
}
.loginbtnr{
  
    border: none;
    border-radius: 1.5rem;
    padding: 15px 30px;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
 
}
.btn.btn-sm, .button.btn-sm {
  padding: 8px 10px !important;
  font-size: 12px;
  text-transform: none;
  line-height: 0.8;
}

.quantity-btn {
  padding: 4px 10px;
  border: none;
  background-color: #dcdcdc;
  color: rgb(0, 0, 0);
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;

}

.quantity-btn:hover {
  background-color: #3b3b3b;
  color: #ffffff;
}

.quantity {
  width: 50px;
  text-align: center;
  padding: 4px 12px;
  border: 1px solid #ccc;
}

.kitfooter .fa {
  display: inline-block;
  font: normal normal normal 25px / 1 FontAwesome !important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cropContainer{
  position: relative;
  width: 100%;
  height: 200px;
  background: #333;
   
}
.cropButton {
  flexShrink: 0;
  marginLeft: 16;
}

.shopping-summery{
  overflow: auto;
}
.ldbox:hover .profile_ulv{
  background: #0062cc;
  color: #ffffff;
  cursor: pointer;
}
.ldbox:hover .profile_ulv h6{
  background: #0062cc;
  color: #ffffff;
}

.dyn.menu-item-has-children .dropdown a {
  /* background: #A9A9A9; */
  padding: 10px 5px;
}

.hederctac1 {
  display: block !important;
  padding: 10px 14px;
  /* background: #ff1658; */
  border-radius: 16px;
  /* color: #ffffff !important; */
}
.hederctac1:focus {
  display: block !important;
  padding: 10px 14px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.hederctac1:active {
  display: block !important;
  padding: 10px 14px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.hederctac {
  display: block !important;
  padding: 10px 14px;
  margin: 0px 5px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.dyn.menu-item-has-children .rdm a {
  /* background: #e2e2e2; */
  padding: 10px 5px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 0px 0;
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 0px 0 0 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #0D6EFD;
  display: block;
}

/* .blink-soft {
    animation: blinker 0.2s linear infinite;
} */

@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink-soft {
  animation: blink-animation 1s infinite;
}

.bg-coverwel {
  /* background-image: url('https://source.unsplash.com/random/1600x900'); */
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 50px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white; */
}
.bg-coverwel1 {
  /* background-image: url('https://source.unsplash.com/random/1600x900');
  background-size: cover;
  background-position: center;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.kycfrom{
  padding: 15px;
}
.kycfrom .form-control {
  border: 1px solid #929292;
  border-radius: 1px;
  height: 34px !important;
  padding-left: 18px;
  font-size: 16px;
  background: #ffffff;
  padding: 7px !important;
  border-radius: 5px;
}
  /* .containerm{
    margin-left: 15;
    margin-right: 15;
  } */
.welcome-messagewel {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 10px;
}

.btn-customwel {
  background-color: #ff5e62;
  border-color: #ff5e62;
  color: white;
}

.btn-customwel:hover {
  background-color: #ff3d47;
  border-color: #ff3d47;
}

.bg-coverwel h5{
  color: #ffffff;
}
.bg-coverwel h6{
  color: #ffffff;
}

.canvasv {
  position: absolute;
  top: 77px;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 9;
}
.canvasvm {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 9999999;
}

/* neww css application home2 */

    /* Top Bar */
    .home22 .top-bar {
      background-color: #dfdfdf47;
      padding: 10px;
      text-align: center;
      color: white;
      font-weight: bold;
      position: sticky;
      top: 0;
      z-index: 1000;
    }

    /* Header */
    .home22  header {
      background-color: #fff;
      padding: 20px 10px;
      position: sticky;
      top: 0;
      z-index: 1000;
      text-align: center;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .home22  header  .search-barr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }

    .home22   header .search-barr input {
      width: 80%;
      padding: 8px;
      border-radius: 50px;
      border: 1px solid #ccc;
    }

    .home22  header .search-barr button {
      padding: 8px 20px;
      margin-left: 5px;
      background-color: #2874F0;
      color: white;
      border: none;
      border-radius: 50px;
      cursor: pointer;
    }

    /* Marquee Section */
    .home22  .marquee {
      background-color: #041a3d;
      color: white;
      padding: 10px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
    }

    .home22  .marquee span {
      display: inline-block;
      animation: marquee 15s linear infinite;
    }

    @keyframes marquee {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    /* category */
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

   .home2222 {
     
      font-family: 'Raleway', sans-serif;
      background-color: #C65D7B;
      padding: 10px;
    }

    .home2222 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .home22    .category {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      gap: 20px; /* Spacing between categories */
      max-width: 400px;
    }

    /* .home22 .box {
      position: relative;
      width: 40%; 
      max-width: 150px;
      aspect-ratio: 1;  
      background-color: #F6E7D8;
      border-radius: 50%;
      overflow: hidden;
    } */
    .home2222 .box {
      position: relative;
      width: 40%; 
      max-width: 150px;
      aspect-ratio: 1;  
      background-color: #F6E7D8;
      border-radius: 50%;
      overflow: hidden;
    }

    /* .home22  .box::before {
      content: '';
      position: absolute;
      inset: -10%;
      background: linear-gradient(#F68989, #874356);
      border-radius: 50%;
      animation: rotate 4s linear infinite;  
      z-index: 0;
    } */
    .home2222  .box::before {
      content: '';
      position: absolute;
      inset: -10%;
      background: linear-gradient(#F68989, #874356);
      border-radius: 50%;
      animation: rotate 4s linear infinite; /* Rotating outer circle */
      z-index: 0;
    }

    .home2222  .box::after {
      content: '';
      position: absolute;
      background-color: #C65D7B;
      inset: 10%;
      border-radius: 50%;
      z-index: 1;
    }

    .home22    .content {
      position: absolute;
      inset: 15%;
      border: 4px solid #F6E7D8;
      z-index: 2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
    }

    .home22   .content img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .home22   .content h2 {
      position: absolute;
      bottom: 10px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fff;
      padding: 5px 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      width: 70%;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
      .home22     .category {
        flex-direction: row; /* Ensures both items stay in a row */
        justify-content: space-around; /* Keeps them aligned properly */
        gap: 10px; /* Reduces the gap on smaller screens */
      }

      .home2222   .box {
        width: 45%;  
        max-width: 130px;  
      }

      .home22   .content h2 {
        font-size: 10px; /* Slightly smaller text for mobile */
        width: 80%;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
  /* catgory end */
    /* .home22  .slider {
      width: 100%;
      margin: 20px auto;
    }

    .home22 .slick-slide img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
    }

    
    .home22 .slick-dots, .home22 .slick-prev, .home22 .slick-next {
      display: none !important;
    } */

    /* Products Section */
    .home22 .products {
      padding: 20px;
      /* background-image: url(https://img.freepik.com/free-photo/pastel-pink-vignette-concrete-textured-background_53876-129734.jpg?semt=ais_hybrid); */
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
     
      /* background: #111; */
    }
    
    .home22 .products h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
      
    }
    
    .home22 .product-row {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
    }
    
    .home22 .product {
      /* text-align: center; */
      flex: 0 0 47%; /* Reduced size for products */
      background: #fff;
      border-radius: 10px;
      /* padding: 5px !important; */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .home22 .product img {
      width: 100%;  
      /* width: 150px;   */
      height: 170px; /* Fixed height */
      object-fit: cover; /* Ensures the image fits the dimensions without distortion */
      border-radius: 8px;
    }
    
    .home22 .product .name {
      font-weight: bold;
      margin: 0px;
      font-size: 0.8rem;
      line-height: 0.9rem;
    }
    
    .home22 .product .price {
      color: #e74c3c;
      font-size: 0.9rem;
      margin-bottom: 0;
      line-height: 1rem;
    }
    

    /* Advertisement Cards */
  

    .home22   .adver{
      font-family: 'Arial', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
     
      background-image: url(https://img.freepik.com/free-photo/pastel-pink-vignette-concrete-textured-background_53876-129734.jpg?semt=ais_hybrid);
    }
    
    .home22 .advertisements {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      width: 100%;
      max-width: 400px; /* Adjust the width */
      overflow: hidden;
    }
    
    .home22 .ad-card {
      width: 48%;  /* Each card takes a large portion of the screen */
      margin-right: 10px;
      perspective: 1000px; /* Enables 3D effect for flip animation */
      position: relative;
    }
    
    .home22 .card {
      position: relative;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      /* animation: flipAnimation 4s infinite;  
      transition: transform 0.5s ease-in-out; */
    }
    
    /* Delay each card's flip animation */
    .home22 .ad-card:nth-child(1) .card {
      animation-delay: 0s; /* First card starts immediately */
    }
    
    .home22 .ad-card:nth-child(2) .card {
      animation-delay: 2s; /* Second card starts 2 seconds after the first */
    }
    @keyframes flipAnimation {
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(180deg); /* Flip halfway */
      }
      100% {
        transform: rotateY(360deg); /* Complete the flip */
      }
    }
    .home22 .ad-card img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
    
    /* For mobile screens */
    @media screen and (max-width: 768px) {
        .home22 .advertisements {
        flex-wrap: nowrap;  /* Ensure no wrapping happens, both cards stay in one row */
        justify-content: space-between;
      }
    
      .home22 .ad-card {
        width: 48%; /* Keep cards at 48% width on mobile */
        margin-right: 10px; /* Space between cards */
        margin-bottom: 0;  /* Remove any vertical margin */
      }
    }
    
    /* About Us */
    .home22  .about-us {
      padding: 20px;
      text-align: center;
      background: #f8f8f8;
    }

    .home22   .about-us img {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 15px;
    }

    /* Footer */
    .filterBytest {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding: 10px 0;
      border-top: 1px solid #ccc;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
      z-index: 3;
      /* height: 300px; */
    }
    .bottom-nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: fixed;
      bottom: 0;
      width: 100%;
      
      background: #fff;
      padding: 10px 0;
      border-top: 1px solid #ccc;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
      z-index: 2;
    }
    
    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #555; /* Default gray color */
      font-size: 12px;
      text-decoration: none;
      flex: 1; /* Ensures equal spacing for all items */
      transition: color 0.3s ease; /* Smooth color transition */
    }
    
    .nav-item img {
      width: 28px;
      height: 28px;
      /* margin-bottom: 5px; */
      /* filter: grayscale(100%) brightness(0.5);  */
      transition: filter 0.3s ease; /* Smooth transition for hover effect */
    }
    
   .nav-item span {
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }
    
    /* Active or Hover State */
     .nav-item:hover,
     .nav-item:active {
      color: #2874F0; /* Blue color for hover */
    }
    
     .nav-item:hover img,
     .nav-item:active img {
      filter: none; /* Removes grayscale on hover */
    }
    
    /* Active State */
     .nav-item.active {
      color: #2874F0; /* Blue color for active state */
    }
    
     .nav-item.active img {
      filter: none; /* Active icon is colored */
    }
    
    /* Responsive Design for Small Screens */
    @media screen and (max-width: 600px) {
       .nav-item img {
        width: 32px;
        height: 32px;
      }
    
       .nav-item span {
        font-size: 12px;
      }
    
       .bottom-nav {
        padding: 12px 0;
      }
    }
    
    /* Blogs */
    .home22     .jm {
      font-family: "Arial", sans-serif;
      margin: 0;
      padding: 0;
      background: #141414;
      color: #fff;
  }

  /* Blogs Section */
.home22  .blogs {
      padding: 40px 20px;
      text-align: center;
  }

  .home22 .blogs h2 {
      font-size: 2.5rem;
      color: #fff;
      position: relative;
      display: inline-block;
      margin-bottom: 50px;
      animation: waveText 2s linear infinite;
      text-transform: uppercase;
  }

  .home22  .blog-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap; /* Allows items to wrap */
  }

  .home22 .blog-item {
      flex: 0 0 48%; /* Makes sure there are two items per row */
      position: relative;
  }

  .home22 .blog-content {
      position: relative;
      background: linear-gradient(145deg, #ff5f6d, #ffc371);
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
      padding: 20px;
      transition: transform 0.6s ease-in-out;
  }

  .home22 .blog-content img {
      width: 100%;
      height: 160px;
      object-fit: cover;
      border-radius: 10px;
  }

  .home22  .blog-content p {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      color: #fff;
      animation: textGlow 1.5s ease-in-out infinite;
  }

  /* Glowing Border Animation */
  .home22  .blog-content::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      border-radius: 10px;
      background: linear-gradient(45deg, #ff5f6d, #ffc371, #ff5f6d, #ffc371);
      background-size: 400% 400%;
      z-index: -1;
      animation: glowing-border 4s linear infinite;
  }

  /* Animations */
  @keyframes glowing-border {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }

  @keyframes waveText {
      0%, 100% {
          transform: translateY(0px);
          color: #ff5f6d;
      }
      50% {
          transform: translateY(-10px);
          color: #ffc371;
      }
  }

  @keyframes textGlow {
      0%, 100% {
          text-shadow: 0 0 5px #fff, 0 0 10px #ffc371, 0 0 15px #ff5f6d;
      }
      50% {
          text-shadow: 0 0 20px #ffc371, 0 0 30px #ff5f6d, 0 0 40px #ff5f6d;
      }
  }

  /* Mobile Responsive */
  @media (max-width: 768px) {
      .home22  .blogs h2 {
          font-size: 1.8rem;
          margin-bottom: 30px;
      }

      .home22 .blog-row {
          display: flex;
          justify-content: space-between;
          gap: 15px;
      }

      .home22  .blog-item {
          flex: 0 0 48%; /* Ensures two items per row */
          margin-bottom: 20px;
      }

      .home22 .blog-content {
          box-shadow: none;
          transform: scale(1);
      }

      .home22   .blog-content::before {
          background-size: 100% 100%;
      }
  }

  /* Very small screens like 300px */
  @media (max-width: 425px) {
      .home22 .blogs h2 {
          font-size: 1.5rem;
          margin-bottom: 20px;
      }

      .home22 .blog-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
      }

      .home22  .blog-item {
          flex: 0 0 48%; /* Makes sure two items per row */
          margin-bottom: 20px;
      }

      .home22  .blog-content {
          box-shadow: none;
          transform: scale(1);
      }

      .home22  .blog-content::before {
          background-size: 100% 100%;
      }
  }

  @media (max-width: 300px) {
      .home22  .blogs h2 {
          font-size: 1.3rem;
          margin-bottom: 10px;
      }

      .home22  .blog-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 5px;
      }

      .home22 .blog-item {
          flex: 0 0 48%; /* Still ensures two items per row on very small screens */
          margin-bottom: 20px;
      }

      .home22  .blog-content {
          box-shadow: none;
          transform: scale(1);
      }

      .home22  .blog-content::before {
          background-size: 100% 100%;
      }
  }

    /* Events */
    .home22   .events-sectionn {
      font-family: Arial, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      
      /* background: linear-gradient(135deg, #fbc2eb, #a6c1ee); */
      overflow: hidden;
    }

    .home22  .collage-container {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 90%;
      max-width: 600px;
      aspect-ratio: 1;
    }

    .home22   .collage-container div {
      background-size: cover;
      background-position: center;
      border: 3px solid #fff; /* White border */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
    }

    .home22   .collage-container div:nth-child(1) {
      background-image: url('https://aghoreshwara.org/rilevent.png'); /* Replace with your image path */
    }

    .home22   .collage-container div:nth-child(2) {
      background-image: url('https://aghoreshwara.org/Business%20event%201.jpeg'); /* Replace with your image path */
    }

    .home22   .collage-container div:nth-child(3) {
      background-image: url('https://aghoreshwara.org/Bussiness%20meeting.jpeg'); /* Replace with your image path */
    }

    .home22  .collage-container div:nth-child(4) {
      background-image: url('https://aghoreshwara.org/ceremony%20img%201%20.jpeg'); /* Replace with your image path */
    }

    .home22   .collage-container div:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    }

    /* Central Circular Logo */
    .home22   .central-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 180px;
      height: 180px;
      background: radial-gradient(circle, #010920, #010920); /* Golden and light pink gradient */
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      border: 4px solid #e0a1b7;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Soft glow effect */
      animation: logo-fade-in 2s ease-in-out forwards, logo-bounce 1.5s infinite ease-in-out;
    }

    /* Animation for logo fading and bouncing */
    @keyframes logo-fade-in {
      0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }

    @keyframes logo-bounce {
      0%, 100% {
        transform: translate(-50%, -50%) scale(1) translateY(0);
      }
      50% {
        transform: translate(-50%, -50%) scale(1.1) translateY(-10px);
      }
    }

    /* Responsive adjustments */
    @media (max-width: 600px) {
      .home22    .collage-container {
        gap: 5px;
      }

      .home22    .central-logo {
        width: 90px;
        height: 90px;
        font-size: 1rem;
      }
    }
/* text animation */
/* General styling for the text */
.home22 .animated-text {
  font-size: 20px;
  color: #333;
  max-width: 800px;
  margin: 20px auto;
  padding: 10px;
  background-color: #111;
  border-radius: 8px;
  line-height: 1.6;
  font-weight: 500;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 2s ease-out forwards, colorChange 3s infinite alternate;
 
}

/* Fade-in animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Color-changing animation */
@keyframes colorChange {
  0% {
    color: #ff6347; /* Tomato */
  }
  25% {
    color: #87cefa; /* LightSkyBlue */
  }
  50% {
    color: #32cd32; /* LimeGreen */
  }
  75% {
    color: #ff4500; /* OrangeRed */
  }
  100% {
    color: #8a2be2; /* BlueViolet */
  }
}



/* animation  */
.home22  p {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
  color: #bce3c9;
}

.home22  .header-animated {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgb(5 2 17) 0%, rgb(7 8 8) 100%);
  color: white;
}

.home22  .logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.home22  .inner-header {
  height: 70vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.home22  .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home22  .waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.home22  .parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.home22  .parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.home22  .parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.home22  .parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.home22  .parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .home22   .waves {
    height: 40px;
    min-height: 40px;
  }
  .home22    p {
    font-size: 24px;
  }
}
/* glowing heading */


.home22  .animated-headinggg {
  font-size: 3rem;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  animation: glow 2s infinite ease-in-out;
}

.home22  .animated-headinggg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.6),
              0 0 30px rgba(255, 255, 255, 0.4),
              0 0 50px rgba(255, 255, 255, 0.3);
  opacity: 0;
  z-index: -1;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes glow {
  0%, 100% {
    color: #fff;
    text-shadow: 0 0 5px #f39c12, 0 0 10px #e67e22, 0 0 20px #e74c3c;
  }
  50% {
    color: #ff9f43;
    text-shadow: 0 0 10px #f39c12, 0 0 20px #e67e22, 0 0 40px #e74c3c;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.1);
  }
}

/* glowing heading end */
/* discount css */
    /* General Card Styling */
    .home22 .jj .card {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: none;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      text-align: center;
      opacity: 0; /* For fade-in effect */
      transform: translateY(50px); /* For fade-in effect */
      animation: fadeIn 0.5s ease-out forwards; /* Trigger fade-in animation */
      background-image: url(https://t4.ftcdn.net/jpg/07/00/38/65/240_F_700386538_LYI9cHrP0wmWQWMJnCvQm4zUQDN0zObw.jpg);
  }

  /* Animation Timing */
  .home22 .jj .card:nth-child(1) { animation-delay: 0.1s; }
  .home22 .jj .card:nth-child(2) { animation-delay: 0.2s; }
  .home22 .jj .card:nth-child(3) { animation-delay: 0.3s; }
  .home22 .jj .card:nth-child(4) { animation-delay: 0.4s; }
  .home22 .jj .card:nth-child(5) { animation-delay: 0.5s; }
  .home22 .jj .card:nth-child(6) { animation-delay: 0.6s; }

  /* Image Styling */
  .home22 .jj .card img {
      /* height: 100px;
      width: 100px;
      object-fit: contain;
      margin: 10px auto; */
          height: 120px;
    width: 100%;
    object-fit: cover;
    margin: 0px auto;
  }


  /* Card Title and Text */
  .home22 .jj .card-title {
      font-size: 0.9rem;
      font-weight: bold;
      margin: 0;
      line-height: 1rem;
  }

  .home22 .jj .card-text {
      font-size: 0.8rem;
      color: #000000;
      margin: 0;
  }

  /* Hover Animation */
  .home22 .jj .card:hover {
      transform: scale(1.05); /* Scale up the card slightly */
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2); /* Intensify shadow */
  }

  /* Fade-in Animation */
  @keyframes fadeIn {
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }

  /* Responsive Grid System */
  .home22 .jj .container {
      margin-top: 20px;
  }

  /* Mobile View: 3 Cards Per Row */
  @media (max-width: 768px) {
      .home22 .jj .row > div {
          flex: 0 0 33.3333%; /* Each card takes 1/3rd width */
          max-width: 33.3333%;
      }
      .home22 .jj .d-flex > div {
          flex: 0 0 43.3333%; /* Each card takes 1/3rd width */
          max-width: 43.3333%;
      }
  }

  /* Desktop View: Default */
  @media (min-width: 769px) {
      .home22 .jj .row > div {
          flex: 0 0 25%; /* 4 Cards Per Row for Larger Screens */
          max-width: 25%;
      }
      .home22 .jj .d-flex > div {
          flex: 0 0 25%; /* 4 Cards Per Row for Larger Screens */
          max-width: 25%;
      }
  }

/* discount css end */
/* brand section start */

.home22  .ril_brands {
  margin: 0;
  font-family: 'Arial', sans-serif;
  /* background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d); */
  /* background-image: url(https://as2.ftcdn.net/v2/jpg/05/92/23/57/1000_F_592235797_TiZCiFTo3WqfpCSThykcxRLjsMB6nACe.jpg);
  background-size: 400% 400%;
  animation: gradientBG 8s ease infinite; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.home22  .ril_brands  h1 {
  font-size: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  /* animation: glowText 1.5s ease-in-out infinite; */
}

.home22  .ril_brands .brand-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* width: 90%; */
}

.home22  .ril_brands .brand {
  width: 173px;
  height: 151px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: transparent;
}

.home22  .ril_brands img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.5s ease;
}

.home22  .ril_brands   .brand:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 15px 40px rgba(255, 255, 255, 0.5);
}

.home22  .ril_brands   .brand:hover img {
  transform: scale(1.1); /* Subtle zoom effect */
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes glowText {
  0% {
    text-shadow: 0 0 10px #ff9a00, 0 0 20px #ffb900, 0 0 30px #ff9a00;
  }
  100% {
    text-shadow: 0 0 20px #ffdb4d, 0 0 30px #ffdb4d, 0 0 40px #ffd700;
  }
}

.ftm .fa{
  font-size: 20px;
  color: #000000;
}

.home2222 .image-container {
  max-width: 100%; /* Image will not exceed 90% of the viewport width */
  height: auto;
 
}
.home2222  img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Optional for rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Optional shadow */
}
  /* Media query for mobile devices */
  @media (max-width: 768px) {
   .home2222 .image-container {
        max-width: 95%; /* Increase width slightly for mobile */
        background-color: #fff;
    }
}
/* brand section end */



/* new for app */
.AppPro .row.product-grid .product-grid {
  /* box-shadow: none; */
}
.AppPro .col-6 {
 padding: 0px;
}
.AppPro .card {
border-radius: 0px;
margin-bottom: 0px;
padding: 5px;
/* border-bottom: none;
border-right: 1px solid #c5c5c5b2;
border-top:  1px solid #c5c5c5b2; */
}
@media only screen and (max-width: 990px) {
  .AppPro  .product-grid {
      margin-bottom: 10px;
  }
}
.AppPro .product-grid .product-image {
  overflow: hidden;
  padding: 1px;
  position: relative;
  box-shadow: none;
}
.AppPro .act {
  border:none;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  min-width: 55px;
  font-weight: 600;
  color: rgb(5, 5, 65);
  font-family: arial;
  font-size: 13px;
  display: inline-block;
  padding: 0px 7px !important;
}
.AppPro .act .fa-podcast{
  display:none;
  
}
.AppPro .act1 {
  font-weight: 650;
  color: #7c7c7c99;
  float: none;
  text-decoration: line-through;
  
 

}
.AppPro .productPage__left {
  
  margin-top: 1vh;
  padding: 5px;
}
.AppPro .productPage__displayImageContainer {
 
  margin-left: 1px;
  
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.AppPro .productPage__displayImage {
  max-width: 92%;
   
}
.AppPro .detail-info .product-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1px 0 3px 0;
}
.AppPro .pps img{
 margin: auto;
}
.AppPro .pps  .slick-dots {
  position: absolute;
  bottom: -15px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.AppPro .pps  .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.AppPro .pps .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.AppPro .pps .slick-dots li button:before {
  font-family: "slick";
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.AppPro .pps .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #3bb77e;
}

.home22 .jj .card {
  padding: 0px;
}
.AppPro .prdehr {
 
  height: 1px !important;
  opacity: 1;
  margin: 0.1rem 0;
}
.AppPro button.addcrtbtn.btn.btn-success {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #0D6EFD !important;
  border-radius: 35px;
  box-shadow: 0px 6px 20px 0px #ff952854;
}
.AppPro .pDetails{
  display: flex;
  justify-content: end;
}
.AppPro .pDetails .product-price {
  padding-top: 1px;
}
.AppPro .cart-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  margin: 16px 0;
}
.AppPro .item-price {
  text-decoration: line-through;
  color: #888;
}
.AppPro .discounted-price {
  font-weight: bold;
  color: #d32f2f;
}
.AppPro .quantity-selector {
  width: 60px;
}
.AppPro     .table td {
  display: table-cell;
  /* width: 100%; */
  text-align: center;
}
.AppPro   .heading-2 {
font-size: 18px;
}
.AppPro .imghe {
padding-left: 0px;
padding-right: 0px;
}
.AppPro .header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  padding: 5px;
}
.AppPro .page-header.breadcrumb-wrap {
  padding: 9px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  font-family: "Quicksand", sans-serif;
}
.AppPro .cart-totals {
  border-radius: 15px;
  /* -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0px 6px 20px 0px #ff952854; */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 10px 10px;
}
.AppPro .table > :not(caption) > * > * {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
}
.AppPro .checkoutbx {
  background: #ffffff;
}
.AppPro .header {
  background-color: #fdfdfd;
  /* background-color: #c8dcff; */
  transition: background 0.3s ease-in-out;
}
.AppPro .content-header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 0.1rem;
}
.AppPro     .content-header .content-title {
  margin-bottom: 0.1rem;
}
.AppPro .card1 {
  
  margin: 0rem;
  /* border-left: 5px solid rgb(24, 130, 178); */
  border: 2px solid #a92298;
  border-radius: 8px;
}
.AppPro .cardred {
  margin: 0rem;
  /* border-left: 5px solid rgb(24, 130, 178); */
  background: #a92298a6;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}
.AppPro .cardlight {
   
  background: #a922984d;
 
}
.AppPro .cardred .iconbox{
  background-color: rgb(255, 255, 255);
  padding: 5px 5px 0px 5px;
  display: inline-block;
  border-radius: 5px;
}
.AppPro   .card2 {
  
  margin: 0rem;
  /* border-left: 5px solid rgb(69, 178, 150); */
  border: 2px solid #a92298;
  border-radius: 8px;
   
}
.AppPro   .card3 {
  
  margin: 0rem;
  /* border-left: 5px solid rgb(250, 149, 50); */
  border: 2px solid #a92298;
  border-radius: 8px;
   
}
.AppPro   .card4 {
  
  margin: 0rem;
  /* border-left: 5px solid rgb(184, 135, 229); */
  border: 2px solid #a92298;
  border-radius: 8px;

}
.AppPro   .card5 {
  
  margin: 0rem;
  /* border-left: 5px solid green; */
  border: 2px solid #a92298;
        border-radius: 8px;
   
}
.AppPro   .pt .puan {
  background: rgb(255, 255, 255);
  box-shadow: none;
  padding: 5px;
  border-radius: 5px;
}
.AppPro   .ListBox {
  background: rgb(255, 255, 255);
 
  padding: 15px 5px;
  border-radius: 5px;
  border-bottom: 1px solid #ccc;
}
.AppPro   .ram {
  background-color: white;
  padding: 15px;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: none;
  border-radius: 5px;
  /* font-size: 12px; */
  cursor: default;
}
.AppPro   .contecttest {
  background-color: #f3f3f3;

  
}
.AppPro .tab-btn {
  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  text-align: center;
  border: 1px solid #757575;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.AppPro .tab-btn img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.AppPro .tab-content img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
}
.AppPro .tab-content p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.AppPro .cateLi {
  /* background: #dfdfdf; */
  background: #dfdfdf70;
  padding-top: 5px;
}
.AppPro .btn-light:hover {
  background-color: #ffffff;
  color: #000 !important;
  border-bottom: 1px solid #ffffff;
}
.AppPro .btn-light.active {
  background-color: #ffffff;
  color: #000 !important;
  border-bottom: 1px solid #ffffff;
}
.AppPro  p {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 14px;
  color: #000000;
}
.AppPro  .CategoryListr {
 overflow: auto;
}

@media only screen and (max-width: 480px) {
 .AppPro .homePageSlider {
      min-height: 190px;
      height: 380px;
  }
  h1{
    font-size: 18px;
  }
  .mb-40 {
    margin-bottom: 10px !important;
}
.mt-50 {
  margin-top: 15px !important;
}
.mb-30 {
  margin-bottom: 10px !important;
}
.p-40 {
  padding: 14px !important;
}
.table td {
  display: table-cell !important;
  width: 100%;
  text-align: center;
}
}

.appCategory .offer-line-height {
  line-height: 15px;
}

.appCategory .main-categories {
  background-color: aliceblue;
}

.appCategory .photo-category {
  padding-left: 10px;
}

.appCategory .photo-category-kids {
  position: relative;
  bottom: 8px;
}
.appCategory .nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #000;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 1px solid var(--bs-nav-tabs-border-color);
}
.appCategory .nav-tabs .nav-link {
  width: 100%;
  margin-bottom: 1px;
  border: 1px solid transparent;
  border-top-left-radius:0.375rem;
  border-top-right-radius: 0.375rem;
  background: transparent;
  margin-top: 5px;
}
.appCategory .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #747474  #747474  #fff;
}
.appCategory  .nav-link.active {
  /* margin-top: 5px; */
  -webkit-transform: translateY(0px); 
   transform: translateY(0px);
  color: #000;
  background-color: #ececec;
  border-color: #747474 #747474 #fff;
}


.notification-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.notification-header {
  display: flex;
  /* align-items: center; */
  gap: 10px;
}
.notification-icon {
  width: 100px;
  height: 50px;
  border-radius: 8px;
  background-color: #ff0000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
}
.notification-body img {
  width: 100%;
  border-radius: 8px;
}
.new-label {
  color: red;
  font-weight: bold;
}
.appsrc input{
  height: 30px;
  border-radius: 5px;
  background-color: #dfdfdf70;
}
.appsrc .react-autosuggest__container{
position: relative;
}
.appsrc .sbta{
position: absolute;
top: 5px;
right: 10px;
}
.appsrc  .search-modal-input{
 display: flex;
 text-align: center;
}
.AppPro  .logo img {
  width: 66px;
}
.AppPro  .content-main {
  /* padding: 15px 3%; */
  margin-left: auto;
  margin-right: auto;
  /* background-color: #f8f9fa; */
}


.myjourney p {
  font-size: 11px;
  letter-spacing: 1px;
}
.myjourney ul{
padding-left: 2rem;
list-style: disc;
}

.descriptionril  {
  font-family: "Lato", sans-serif !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  font-size: 24px !important;
  color: #bce3c9 !important;
}

.ofrP {
  font-size: 19px !important;
  margin-bottom: 5px;
}

.prolable{
  background-color: #EBF3FE;
  padding: 5px;
  border: 1px solid #ddd;
}


.filter-list {
  background: #f2f2f2;
  /* height: 100%; */


  min-height: 380px;
}
.filter-list li{
  padding:2px 5px;
  background: #f2f2f2;
  border-left: 3px solid #f2f2f2;
}
.filter-list .active{
  padding:2px 5px;
  background: #fff;
  color: red;
  border-left: 3px solid red;
}

.btst {
  top: 10px;
  right: 10px;
}
.btst .fas{
  font-size: 22px;
  color:red
}
.btst .vstt{
  font-size: 22px;
}

.fts {
  font-size: 12px;
}

.apptbs td {
  text-align: left;
}

.vsd {
  background-color: #088d4f !important;
  padding: 10px !important;
}
.btn.btn-primary {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px !important;
  color: #ffffff;
  border: none;
  background-color: #a92298a6;
  border-radius: 4px;
}

.pt .puan #avatarnew  {
  position: absolute;
  top: -40px;

  
}
.pt .puan #avatarnew1  {
  position: absolute;
  top: -40px;

  
}
.pt .puan #avatarnew2  {
  position: absolute;
  top: -40px;

  
}
.pt .puan #avatarnew3  {
  position: absolute;
  top: -40px;

  
}
.pt .puan #avatarnew4  {
  position: absolute;
  top: -40px;

  
}
.pt .puan #avatarnew img {
  width: 85px;
  height: 85px;
  /* border-radius: 43px; */
  /* border: 5px solid ;
  border-color: #fff;
  border-left-color: #000;
  border-bottom-color: #000; */
  
  border-radius: 50%; /* Pure gol (circular) shape */
  position: relative;
  border: 5px solid transparent;
}
.pt .puan #avatarnew1 img {
  width: 85px;
  height: 85px;
  /* border-radius: 43px; */
  /* border: 5px solid ;
  border-color: #fff;
  border-left-color: #000;
  border-bottom-color: #000; */
  
  border-radius: 50%; /* Pure gol (circular) shape */
  position: relative;
  border: 5px solid transparent;
}
.pt .puan #avatarnew2 img {
  width: 85px;
  height: 85px;
  /* border-radius: 43px; */
  /* border: 5px solid ;
  border-color: #fff;
  border-left-color: #000;
  border-bottom-color: #000; */
  
  border-radius: 50%; /* Pure gol (circular) shape */
  position: relative;
  border: 5px solid transparent;
}
.pt .puan #avatarnew3 img {
  width: 85px;
  height: 85px;
  /* border-radius: 43px; */
  /* border: 5px solid ;
  border-color: #fff;
  border-left-color: #000;
  border-bottom-color: #000; */
  
  border-radius: 50%; /* Pure gol (circular) shape */
  position: relative;
  border: 5px solid transparent;
}
.pt .puan #avatarnew4 img {
  width: 85px;
  height: 85px;
  /* border-radius: 43px; */
  /* border: 5px solid ;
  border-color: #fff;
  border-left-color: #000;
  border-bottom-color: #000; */
  
  border-radius: 50%; /* Pure gol (circular) shape */
  position: relative;
  border: 5px solid transparent;
}
.pt .puan #avatarnew::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: linear-gradient(to right, #a92298 50%, red 34%);
  border-radius: 42px 42px 42px 42px;
}
.pt .puan #avatarnew1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: linear-gradient(to right, #a92298 70%, red 34%);
  border-radius: 42px 42px 42px 42px;
}
.pt .puan #avatarnew2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: linear-gradient(to right, #a92298 90%, red 34%);
  border-radius: 42px 42px 42px 42px;
}
.pt .puan #avatarnew3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: linear-gradient(to right, #a92298 100%, red 34%);
  border-radius: 42px 42px 42px 42px;
}
.pt .puan #avatarnew4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: linear-gradient(to right, #a92298 0%, red 0%);
  border-radius: 42px 42px 42px 42px;
}

.testNewd{
  background: #07224e;
  height: 100px;
}

.evenclm{
  background: #f2f2f2;
}

.oddclm {
  background: #fff;
}

.cardProFile{
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #d8e8ee;
}
.cardProFile1{
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #e5f3f8;
  padding: 10px;
  margin-top: 10px;
}


#avatarvsm {
  padding-left: 10px;
  position: relative;
  width: 100px;
}

.pt .puan #avatarvsm img {
  width: 80px;
  height: 80px;
  /* border-radius: 215px; */
}

.btn-outline-danger{
  color: #dc3545 !important;
    border-color: #dc3545 !important;
    background-color:transparent !important;
}

.btn-info {
  color: #000 !important;
  background-color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}

.activevm {
  color: #a92298;
}
.activevm .fa{
  color: #a92298;
}

.ftm i{
  font-size: 20px;
}

.homeColor{
  /* background-color: #d8e8ee; */
  height: 100vh;
  background-image: linear-gradient(#fff,#a71195b8,#fff,#fff,white, white);
}

.homeColor .headervs{
  background: transparent !important;
  transition: all 0.3s ease-in-out;
}
.homeColor .AppPro .page-header.breadcrumb-wrap {
  padding: 9px;
  background-color: transparent !important;
  border-bottom: 1px solid #ececec;
  font-family: "Quicksand", sans-serif;
}

.newbtn {
  display: block !important;
  padding: 2px 3px !important;
}

.activem {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid #a92298;
  text-align: center;
  color: #a92298;
}
.inactivem {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  text-align: center;
  color: black;
}

.myjourney .more-content {
  display: none;
}

.myjourney  .read-more-btn {
  cursor: pointer;
}

.myjourney .bg-primary-color {
  background-color: rgb(187, 187, 250);
}




.myjourney .more-content1 {
  display: none;
}

.myjourney .read-more-btn1 {
  cursor: pointer;
}

.myjourney .bg-secondary-color {
  background-color: rgb(209, 204, 204);
}



.myjourney  .more-content2 {
  display: none;
}

.myjourney  .read-more-btn2 {
  cursor: pointer;
}

.myjourney  .bg-success-color {
  background-color: rgb(155, 243, 155);
}



.myjourney  .more-content3 {
  display: none;
}

.myjourney  .read-more-btn3 {
  cursor: pointer;
}

.myjourney  .bg-danger-color {
  background-color: rgb(250, 149, 149);
}



.myjourney  .more-content4 {
  display: none;
}

.myjourney  .read-more-btn4 {
  cursor: pointer;
}
.myjourney  .bg-warning-color{
  background-color: rgb(252, 252, 138);
}


.myjourney .more-content5 {
  display: none;
}

.myjourney .read-more-btn5 {
  cursor: pointer;
}
.myjourney .bg-info-color{
  background-color: rgb(156, 217, 241);
}


.myjourney .more-content6 {
  display: none;
}

.myjourney .read-more-btn6 {
  cursor: pointer;
}
.myjourney  .bg-primary-color2{
  background-color: rgb(235, 176, 235);
}

.cardProFilevd {
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
  padding: 10px;
}

 

.bottom-nav.ftm .nav-item:hover,.bottom-nav.ftm .nav-item:active {
  color: #a92298 !important;
}


.btnm  {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 30px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid transparent;
  /* background-color: #0D6EFD; */
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  letter-spacing: 0.5px;
}

/* loader */



.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.spinner {
  height: 50px;
  width: max-content;
  font-size: 18px;
  font-weight: 600;
  font-family: monospace;
  letter-spacing: 1em;
  color: #000000;
  filter: drop-shadow(0 0 10px);
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
 .spinner span {
  animation: loading6454 1.75s ease infinite;
 }
 
 .spinner span:nth-child(2) {
  animation-delay: 0.25s;
 }
 
 .spinner span:nth-child(3) {
  animation-delay: 0.5s;
 }
 
 .spinner span:nth-child(4) {
  animation-delay: 0.75s;
 }
 
 .spinner span:nth-child(5) {
  animation-delay: 1s;
 }
 
 .spinner span:nth-child(6) {
  animation-delay: 1.25s;
 }
 .antrow-active {
  background-color: #ff1100 !important; /* Light green */
  color: #ffffff !important;
}
 .antrow-active:hover {
  /* Light green */
  color: #000000 !important;
}

/* Styles for inactive rows */
.antrow-inactive {
  background-color: #fff1f0; /* Light red */
}
 .spinner span:nth-child(7) {
  animation-delay: 1.5s;
 }
 
 @keyframes loading6454 {
  0%, 100% {
   transform: translateY(0);
  }
 
  50% {
   transform: translateY(-10px);
  }
 }

 /* From Uiverse.io by adamgiebl */ 
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 13px;
  width: 13px;
  margin-right: 22px;
  border-radius: 10px;
  background-color: #a92298;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #a92298;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #a9229880;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #a92298;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}


.infinite-scroll-component  .recentlostfound{
  padding-right :0px;
  padding-left: 0px;


}

/* loader end */